import { createApi } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE_KEYS } from 'app/constants/app';
import { EAuthMethods, ILogInForm, ILogInResponse, IResetPasswordBody, ISendCodeForm } from 'interface';
import { tokenService, usabilityService } from 'services';
import { customFetchBase } from 'store/utils';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    //TODO change response type
    register: builder.mutation<any, { email: string; password: string; confirm_password: string; referralCode?: string }>({
      query: (data) => {
        return {
          url: '/auth/registration/email',
          method: 'POST',
          body: data,
        };
      },
    }),
    login: builder.mutation<ILogInResponse, ILogInForm>({
      query: (data) => {
        return {
          url: '/auth/login/email',
          method: 'POST',
          body: data,
        };
      },
      transformResponse(response: ILogInResponse) {
        tokenService.setAccessToken(response?.accessToken);
        tokenService.setRefreshToken(response?.refreshToken);
        usabilityService.set(LOCAL_STORAGE_KEYS.HAVE_BEEN_LOGGED_BEFORE, true);
        usabilityService.setIsRegistratedWithEmail(true);
        return response;
      },
    }),
    sendOtpCode: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/auth/email/send-code',
          method: 'POST',
          body: data,
        };
      },
    }),
    loginOtp: builder.mutation<ILogInResponse, { code: string; email: string }>({
      query: (data) => {
        let body: any = { ...data };
        const referralCode = localStorage.getItem(LOCAL_STORAGE_KEYS.REFERRAL_CODE);
        const onboardingExpBonus = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.UNAUTH_REWARD_EXP)) || undefined;
        if (referralCode) {
          body.referralCode = referralCode;
        }
        if (onboardingExpBonus) body.onboardingExpBonus = onboardingExpBonus;
        return {
          url: '/auth/email/verify',
          method: 'POST',
          body,
        };
      },
      transformResponse(response: ILogInResponse) {
        tokenService.setAccessToken(response?.accessToken);
        tokenService.setRefreshToken(response?.refreshToken);
        usabilityService.set(LOCAL_STORAGE_KEYS.HAVE_BEEN_LOGGED_BEFORE, true);
        usabilityService.setIsRegistratedWithEmail(true);
        return response;
      },
    }),
    loginMFA: builder.mutation<
      ILogInResponse,
      // TODO add types
      { type: EAuthMethods; data: any }
    >({
      query: ({ data, type }) => {
        let body: any = { ...data };
        const referralCode = localStorage.getItem(LOCAL_STORAGE_KEYS.REFERRAL_CODE);
        const onboardingExpBonus = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.UNAUTH_REWARD_EXP)) || undefined;
        if (referralCode) body.referralCode = referralCode;
        if (onboardingExpBonus) body.onboardingExpBonus = onboardingExpBonus;
        return {
          url: `/auth/login/${type}`,
          method: 'POST',
          body,
        };
      },
      transformResponse(response: ILogInResponse, meta, arg) {
        tokenService.setAccessToken(response?.accessToken);
        tokenService.setRefreshToken(response?.refreshToken);
        usabilityService.set(LOCAL_STORAGE_KEYS.HAVE_BEEN_LOGGED_BEFORE, true);
        if ([EAuthMethods.Email, EAuthMethods.Google].includes(arg?.type)) {
          usabilityService.setIsRegistratedWithEmail(true);
        }
        return response;
      },
    }),
    //TODO change response type
    refreshToken: builder.mutation({
      query: (data) => {
        return {
          url: '/auth/refresh',
          method: 'POST',
          body: data,
        };
      },
      transformResponse(response: ILogInResponse) {
        tokenService.setAccessToken(response?.accessToken);
        tokenService.setRefreshToken(response?.refreshToken);
        return response;
      },
    }),
    //TODO change response type
    resetPasswordSendCode: builder.mutation<any, ISendCodeForm>({
      query: (data) => {
        return {
          url: '/auth/pass-reset',
          method: 'POST',
          body: data,
        };
      },
    }),
    //TODO change response type
    resetPasswordCheckCode: builder.mutation<any, IResetPasswordBody>({
      query: (data) => {
        return {
          url: '/auth/pass-check',
          method: 'POST',
          body: data,
        };
      },
    }),
    //TODO change response type
    resetPasswordSetNewPassword: builder.mutation<any, IResetPasswordBody>({
      query: (data) => {
        return {
          url: '/auth/pass-confirm',
          method: 'POST',
          body: data,
        };
      },
    }),
    logOut: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/auth/logout',
          method: 'POST',
        };
      },
      transformResponse(response: ILogInResponse) {
        tokenService.setRemoveTokens();
        return response;
      },
    }),
    attachAuthMethod: builder.mutation<ILogInResponse, { type: EAuthMethods; data: any }>({
      query: ({ data, type }) => ({
        url: `/auth/attach/${type}`,
        method: 'POST',
        body: data,
      }),
      transformResponse(response: ILogInResponse) {
        tokenService.setAccessToken(response?.accessToken);
        tokenService.setRefreshToken(response?.refreshToken);
        return response;
      },
    }),
    unattachAuthMethod: builder.query<any, EAuthMethods>({
      query: (type) => ({
        url: `/auth/unattach/${type}`,
      }),
    }),
    generateNonce: builder.query<{ nonce: string }, void>({
      query: () => ({ url: '/auth/generate-nonce' }),
    }),
  }),
});

export const {
  useResetPasswordCheckCodeMutation,
  useResetPasswordSetNewPasswordMutation,
  useResetPasswordSendCodeMutation,
  useRegisterMutation,
  useLoginMutation,
  useSendOtpCodeMutation,
  useLoginOtpMutation,
  useRefreshTokenMutation,
  useLogOutMutation,
  useLoginMFAMutation,
  useAttachAuthMethodMutation,
  useLazyUnattachAuthMethodQuery,
  useLazyGenerateNonceQuery,
} = authApi;
