export enum ROUTES {
  MAIN = '/',
  PROFILE = '/profile',
  OTHER_PROFILE = '/profile/:userId',
  ACTIVATE = '/activate',
  FULLSCREEN = '/fullscreen',
  FULLSCREEN_PREMIUM = '/fullscreen/premium',
  MEDITATION = '/fullscreen/play/meditation',
  MEDITATION_PLAYER = 'play/meditation/:id',
  FULLSCREEN_MINDBATTLE = '/fullscreen/play/mindbattle',
  MINDBATTLE_1V1 = '/fullscreen/play/mindbattle/:battleId',
  BOOKS = 'play/books',
  READ_BOOK = 'play/books/:id',
  SHOP = '/shop',
  USER = '/user/',
  COURSES = '/courses',
  PLAY = '/play',
  INFO = '/docs',
  SETTINGS = '/settings',
  CODE = '/code',
  SEARCH = '/search',
  AUTH = '/auth',
  AUTH_TELEGRAM = '/auth/telegram',
  ACTIVATION_CODE = '/activation_code',
  OTP_ACTIVATION_CODE = '/otp_activation_code',
  AUTHEMAILOTP = '/email-otp',
  LOGIN = '/email/sign-in',
  OTP_LOGIN = '/email-otp/sign-in',
  SIGN_UP = '/email/sign-up',
  RESET_PASSWORD = '/reset-password',
  GOVERN = '/govern',
  MINDBATTLE = 'mindbattle',
  COURSES_OVERVIEW = '/courses/:courseId',
  COURSES_OVERVIEW_REST = '/courses/:courseId/*',
  COURSES_LESSON_PAGE = '/courses/:courseId/:chapterId/:lessonId',
  COURSES_CONSTRUCTOR = '/courses/edit',
  COURSES_CONSTRUCTOR_CHAPTERS = '/courses/edit/course/:courseId',
  COURSES_CONSTRUCTOR_LESSONS = '/courses/edit/chapter/:chapterId',
  COURSES_EDITOR_EDIT_LESSON = '/courses/edit/lessons/:lessonId',
  SUBMIT_ITEM = '/submit-item',
  CONFIRMATION = '/confirmation',
  SOCIAL = '/social',
  ERROR = '/error',
  CHESTS = 'shop/chests/:type',
  PREMIUM = 'premium',
  PREMIUM_TEST = 'premium_test',
  CERTIFICATES = 'certificates',
  LEADERBOARD = '/play/leaderboard',
  SPACE_LEADERBOARD = '/play/leaderboard/:spaceName',
  WEBVIEW_REDIRECT = 'webview',
  REFERRALS = '/referrals',
  SPACES = '/spaces',
  SPACE = '/:spaceName',
  SPACE_EDIT = '/:spaceName/edit',
  SPACE_COURSE_EDIT = 'course/:courseId',
  SPACE_CHAPTER_EDIT = 'chapter/:chapterId',
  SPACE_LESSON_EDIT = 'lesson/:lessonId',
  SPACE_CREATION = '/space/create',
  FEED = '/spaces/feed',
  SPACE_PARTNER_ID = '/space/:spaceName',
  REFERRAL_LANDING = '/ref/:referralCode',
  TERMS = '/terms',
  POLICY = '/policy',
}
