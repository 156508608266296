import { useEffect } from 'react';
import { dispatch } from './useDispatch';
import { DEFAULT_STUDY_PADDING, EPartnerParams, IPartnerState, setPartnerData } from 'store';
import { LOCAL_STORAGE_KEYS, THEMES } from 'app/constants';
import { useGetPartnerId } from './useGetStoreGeneralData';

const useInitPartnerParams = () => {
  const location = window.location.search;
  const currentPartnerId = useGetPartnerId();

  useEffect(() => {
    const searchParams = new URLSearchParams(location);
    const theme: THEMES =
      (searchParams.get(LOCAL_STORAGE_KEYS.THEME) as THEMES) ?? (localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) as THEMES) ?? THEMES.DARK;

    const partnerDataCollected: IPartnerState = {
      [EPartnerParams.PartnerID]: searchParams.get(EPartnerParams.PartnerID) || null,
      [EPartnerParams.HideSidebards]: searchParams.get(EPartnerParams.HideSidebards) === 'true',
      [EPartnerParams.ScrollOff]: searchParams.get(EPartnerParams.ScrollOff) === 'true',
      [EPartnerParams.StudyHeadOff]: searchParams.get(EPartnerParams.StudyHeadOff) === 'true',
      [EPartnerParams.HideNextLessonBtn]: searchParams.get(EPartnerParams.HideNextLessonBtn) === 'true',
      [EPartnerParams.StudyBottomMargin]: searchParams.get(EPartnerParams.StudyBottomMargin)
        ? parseInt(searchParams.get(EPartnerParams.StudyBottomMargin) || DEFAULT_STUDY_PADDING.toString())
        : undefined,
      [EPartnerParams.HideBackButton]: searchParams.get(EPartnerParams.HideBackButton) === 'true',
      [EPartnerParams.StudyScrollbarHidden]: searchParams.get(EPartnerParams.StudyScrollbarHidden) === 'true',
      [EPartnerParams.Theme]: theme,
    };

    // TODO: подумать, возвращать ли токен и делать ли проверку на него

    if (!!partnerDataCollected[EPartnerParams.PartnerID] && currentPartnerId !== partnerDataCollected[EPartnerParams.PartnerID]) {
      dispatch(setPartnerData(partnerDataCollected));
    }
  }, [location, currentPartnerId]);
};

export default useInitPartnerParams;
