import { ITEM_IDS } from 'app/constants';
import { IDatabaseItem, IShopItem } from 'interface';
import exp from 'components/library/images/ShopInventory/exp_token_200x200.png';
import expTokenLarge from 'components/library/images/itemPage/exp_token_lg.png';

export const expToken: IDatabaseItem = {
  id: ITEM_IDS.EXP_TOKEN,
  name: 'EXP Point',
  image: exp,
  imageThumb: expTokenLarge,
  description: `Points earned for completing activities on our platform`,
  type: 'fungible',
  fungibleType: null,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  grade: 'common',
  blockchain: null,
  contract: null,
  url: '',
};

export const expTokenItem: IShopItem = {
  id: ITEM_IDS.EXP_TOKEN,
  item: expToken,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  quantity: 0,
  price: 1,
};
