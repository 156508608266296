import {
  DEFAULT_STUDY_PADDING,
  getHideBackButton,
  getHideNextLessonBtn,
  getHideSidebards,
  getScrollOff,
  getStudyBottomMargin,
  getStudyHeadOff,
  getStudyScrollbarHidden,
} from 'store';
import { useSelector } from 'react-redux';

export const useScrollOff = () => {
  return useSelector(getScrollOff);
};

export const useHideBackButton = () => {
  return useSelector(getHideBackButton);
};

export const useStudyScrollbarHidden = () => {
  return useSelector(getStudyScrollbarHidden);
};

export const useHideSidebards = () => {
  return useSelector(getHideSidebards);
};

export const useStudyHeadOff = () => {
  return useSelector(getStudyHeadOff);
};

export const useHideNextLessonBtn = () => {
  return useSelector(getHideNextLessonBtn);
};

export const useStudyBottomMargin = () => {
  const studyBottomMarginRaw = useSelector(getStudyBottomMargin);
  if (!studyBottomMarginRaw) return DEFAULT_STUDY_PADDING;
  return studyBottomMarginRaw;
};
