import clsx from 'clsx';
import { ELogoSizes } from 'app/constants';

import { AnimateText } from '../AnimateText/AnimateText';

import * as Styled from './styles';
import { useGetSettings } from 'hooks';

interface IProps {
  containerClassName?: string;
  imageClassName?: string;
  withLabel?: boolean;
  withAnimation?: boolean;
  size?: `${ELogoSizes}`;
  width?: string | number;
  height?: string | number;
  inline?: boolean;
  isAcademyLabel?: boolean;
}

export function Logo({
  containerClassName = '',
  imageClassName = '',
  withLabel = false,
  withAnimation = false,
  size = 'regular',
  width,
  height,
  inline,
  // TODO: temporary prop, decide how to handle academy label when whitelabeling
  isAcademyLabel = false,
}: IProps) {
  const { displayedName, logos } = useGetSettings();
  const logo = logos[size];

  const currentText = () => {
    if (withLabel && withAnimation) {
      return <Styled.Title as={AnimateText} text={displayedName} delay={0.2} />;
    }
    if (withLabel) {
      return <Styled.Title>{displayedName}</Styled.Title>;
    }
    return null;
  };

  if (isAcademyLabel) {
    return (
      <Styled.AcademyWrapper style={{ width, height }} className={clsx({ [containerClassName]: !!containerClassName })}>
        <Styled.Container style={{ display: inline ? 'inline-flex' : 'flex' }}>
          <Styled.Image className={clsx({ [imageClassName]: !!imageClassName })} src={logo} alt={displayedName} />
          {currentText()}
        </Styled.Container>
        <Styled.AcademyLabel>ACADEMY</Styled.AcademyLabel>
      </Styled.AcademyWrapper>
    );
  }

  return (
    <Styled.Container
      style={{ width, height, display: inline ? 'inline-flex' : 'flex' }}
      className={clsx({ [containerClassName]: !!containerClassName })}
    >
      <Styled.Image className={clsx({ [imageClassName]: !!imageClassName })} src={logo} alt={displayedName} />
      {currentText()}
    </Styled.Container>
  );
}
