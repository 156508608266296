import React, { memo, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import {
  setCurrentLanguage,
  setCurrentTheme,
  setLessonPageVolume,
  useRefreshTokenMutation,
  useLazyFetchItemsQuery,
  useLazyGetSettingsQuery,
  useLazyGetUserDataQuery,
} from 'store';
import { ThemeProvider } from 'styled-components';
import { tokenService } from 'services';
import { LANGS, LOCAL_STORAGE_KEYS, THEMES } from './constants';
import { dispatch, useDisplay, useGetCurrentLanguage, useGetCurrentTheme, useGetIsAuth, useGetIsInit, useGetSettings } from 'hooks';
import { AppUiContextProvider } from 'context/AppUiContext';
import { PingOnline } from 'components/library/pingOnline';
// import Pages from 'pages';
import useInitPartnerParams from 'hooks/usePartnerParams';

import { GlobalLoader } from 'shared/utils';
import { colorsDark, colorsLight, darkTheme, GlobalStyles, lightTheme } from 'components/library/colors/theme';

import '../App.css';
import '../styles/normalize.css';
import { useClientDetection } from 'utils/useClientDetection';
import clsx from 'clsx';

const Pages = React.lazy(() => import('pages'));

const Ping = () => {
  if (process.env.NODE_ENV === 'production') return <PingOnline />;
  return null;
};

const App = () => {
  const isInitApp = useGetIsInit();
  const isAuth = useGetIsAuth();
  const currentLanguage = useGetCurrentLanguage();
  const currentTheme = useGetCurrentTheme();
  const { displayedName } = useGetSettings();
  const { isMobile } = useDisplay();
  const { os } = useClientDetection();
  const isIOS = os === 'iOS';
  const [refreshToken] = useRefreshTokenMutation();
  const [fetchItems] = useLazyFetchItemsQuery();
  const [fetchSettings] = useLazyGetSettingsQuery();
  const [fetchUser] = useLazyGetUserDataQuery();
  useInitPartnerParams();

  useEffect(() => {
    document.title = `${displayedName} Study`;
  }, [displayedName]);

  useEffect(() => {
    if (!isInitApp) {
      initApp();
    }
  }, [isInitApp]);

  useEffect(() => {
    if (isAuth) {
      fetchUser();
    }
  }, [isAuth]);

  useEffect(() => {
    fetchItems();
    fetchSettings();
  }, [fetchItems, fetchSettings]);

  const initApp = async () => {
    const localLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE) as LANGS;
    dispatch(setCurrentLanguage(localLang || currentLanguage));
    const localTheme = localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) as THEMES;
    dispatch(setCurrentTheme(localTheme || currentTheme));
    const localLessPageVolume = localStorage.getItem(LOCAL_STORAGE_KEYS.LESSON_PAGE_VOLUME);
    dispatch(setLessonPageVolume(localLessPageVolume ? +localLessPageVolume : 100));
    const token = tokenService.getRefreshToken();
    await refreshToken({ refreshToken: token }).unwrap().catch(console.error);
  };

  if (!isInitApp) {
    return null;
  }

  return (
    <ThemeProvider
      theme={
        currentTheme === THEMES.LIGHT ? { theme: lightTheme, colors: colorsLight, dark: false } : { theme: darkTheme, colors: colorsDark, dark: true }
      }
    >
      <AppUiContextProvider>
        <Ping />
        <GlobalStyles />
        <ToastContainer autoClose={10000} className={clsx({ isIOS })} position="top-right" icon={false} draggable={isMobile} />
        <AnimatePresence>
          <Suspense fallback={<GlobalLoader isLoading={false} />}>
            <Pages />
          </Suspense>
        </AnimatePresence>
      </AppUiContextProvider>
    </ThemeProvider>
  );
};

export default memo(App);
